import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/Seo';
import NavBar from '../components/nav';
import Footer from '../components/Footer';

const CookiePolicy = () => (
  <Layout>
    <Seo title={'Cookie Policy'} />
    <NavBar
      navClass={'white-navbar'}
      logo={'/assets/main-logo-blue.svg'}
      class={'text-darkblue border-darkblue'}
    />
    <section className="mx-auto max-w-5xl py-32 space-y-4 px-6 font-poppins">
      <h1 className="text-3xl font-arima text-darkblue pt-6">Cookie Policy</h1>

      <p className="text-md text-gray-500">
        This is the Cookie Policy for QIVA Global, accessible from &nbsp;
        <a className="underline" href="https://qivaglobal.com/">qivaglobal.com</a>
      </p>

      <h2 className="font-arima text-darkblue pt-8 text-2xl">What Are Cookies</h2>

      <p className="text-md text-gray-500">
        As is common practice with almost all professional websites this site
        uses cookies, which are tiny files that are downloaded to your computer,
        to improve your experience. This page describes what information they
        gather, how we use it and why we sometimes need to store these cookies.
        We will also share how you can prevent these cookies from being stored
        however this may downgrade or 'break' certain elements of the sites
        functionality.
      </p>

      <h2 className="font-arima text-darkblue pt-8 text-2xl">How We Use Cookies</h2>

      <p className="text-md text-gray-500">
        We use cookies for a variety of reasons detailed below. Unfortunately in
        most cases there are no industry standard options for disabling cookies
        without completely disabling the functionality and features they add to
        this site. It is recommended that you leave on all cookies if you are
        not sure whether you need them or not in case they are used to provide a
        service that you use.
      </p>

      <h2 className="font-arima text-darkblue pt-8 text-2xl">Disabling Cookies</h2>

      <p className="text-md text-gray-500">
        You can prevent the setting of cookies by adjusting the settings on your
        browser (see your browser Help for how to do this). Be aware that
        disabling cookies will affect the functionality of this and many other
        websites that you visit. Disabling cookies will usually result in also
        disabling certain functionality and features of the this site. Therefore
        it is recommended that you do not disable cookies.
      </p>

      <h2 className="font-arima text-darkblue pt-8 text-2xl">Third Party Cookies</h2>

      <p className="text-md text-gray-500">
        In some special cases we also use cookies provided by trusted third
        parties. The following section details which third party cookies you
        might encounter through this site.
      </p>

      <p className="text-md text-gray-500">
        This site uses Google Analytics which is one of the most widespread and
        trusted analytics solution on the web for helping us to understand how
        you use the site and ways that we can improve your experience. These
        cookies may track things such as how long you spend on the site and the
        pages that you visit so we can continue to produce engaging content.
      </p>

      <p className="text-md text-gray-500">
        For more information on Google Analytics cookies, see the official
        Google Analytics page.
      </p>

      <h2 className="font-arima text-darkblue pt-8 text-2xl">More Information</h2>

      <p className="text-md text-gray-500">
        Hopefully that has clarified things for you and as was previously
        mentioned if there is something that you aren't sure whether you need or
        not it's usually safer to leave cookies enabled in case it does interact
        with one of the features you use on our site.
      </p>
    </section>
    <Footer />
  </Layout>
);

export default CookiePolicy;
